.upgrade-loader-modal.modal-backdrop-show.show {
  opacity: .7;
}

.bg-4c4c4c {
  background-color: #4c4c4c;
}

.upgrade-loader-modal-content .progress,
.upgrade-loader-modal-content .progress-bar {
  border-radius: 20px;
}

.upgrade-loader-modal-content .progress-bar {
  background-color: #06b191;
}