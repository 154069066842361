.cp {
  cursor: pointer;
}

.calendar-wrapper {
  background-color: #fff;
  margin-top: .5rem;
}

/* .calendar-wrapper ::-webkit-scrollbar {
  height: 0;
  width: 0;
} */

.cla-header {
  border: 1px solid #ddd;
  border-bottom: none;
  background-color: #f4f4f4;
  font-weight: bold;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.day-haeder p,
.event p {
  margin-bottom: 0;
}

.cla-header .cla-title {
  margin-right: auto;
  position: relative;
}

.cla-header .cla-head-calender {
  display: none;
  position: absolute;
  z-index: 100;
  top: 120%;
}

.cla-header .cla-head-calender.open {
  display: block;
}

.cla-header select {
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: inherit;
  border-radius: 6px;
  padding: 3px 36px 3px 6px;
  font-weight: bold;
  font-size: 14px;
  width: max-content;
  appearance: none;

  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #fff, #fff);
  background-position:
    calc(100% - 19px) .9em,
    calc(100% - 14px) .9em,
    100%;
}

.today {
  padding: 2px 12px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.today.canRefresh {
  border: 1px solid hsl(168, 53%, 45%);
  background-color: hsl(168, 53%, 45%);
  color: #fff;
}

.cla-arr {
  border-radius: 50%;
  padding: .25rem;
}

.today:hover,
.cla-arr:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.today.canRefresh:hover {
  background-color: hsl(168, 53%, 40%);
}

.prev-arr {
  transform: rotateZ(90deg);
}

.next-arr {
  transform: rotateZ(-90deg);
}

.prev-arr path,
.next-arr path {
  fill: rgba(0, 0, 0, 0.9);
}

.calendar-wrapper .fc .fc-timegrid-slot {
  height: 7rem;
}

.calendar-wrapper .fc-day-today {
  background: #fff !important;
}

.calendar-wrapper .fc-theme-standard th:not(:first-child) {
  border: none;
  border-bottom: 1px solid #ddd;
  height: 4.5rem;
}

.calendar-wrapper .fc .fc-timegrid-slot-label {
  font-size: 0.9em;
  vertical-align: top;
  color: rgba(0, 0, 0, 0.7);
  padding-top: 0.8rem;
  margin: 0 4px;
}

.calendar-wrapper .fc-direction-ltr .fc-timegrid-slot-label-frame {
  text-align: center;
}

.calendar-wrapper .fc-v-event {
  background-color: #dff6f1;
  border-radius: 4px;
  box-shadow: none;
  border: 0;
  margin: 0;
}

.calendar-wrapper .fc-v-event .forC {
  position: absolute;
  background-color: #36b29a;
  z-index: -1;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}

.calendar-wrapper .fc-v-event .start {
  position: absolute;
  background-color: #fff;
  border-radius: 16px;
  padding: 2px 16px;
  cursor: pointer;
  color: #36b29a;
  bottom: 3px;
  right: 3px;
}

.calendar-wrapper .event {
  background-color: #dff6f1;
  border-radius: 0 3px 3px 0;
  padding-left: 14px;
  color: rgba(0, 0, 0, 0.8);
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.calendar-wrapper .event.isSmall {
  gap: 2px;
}

.calendar-wrapper .fc-event-selected:after,
.calendar-wrapper .fc-event:focus:after {
  background: transparent;
}

.calendar-wrapper .event.isActive {
  background-color: #36b29a;
  color: #fff;
}

.calendar-wrapper .event.isActive svg {
  fill: #fff !important;
}

.calendar-wrapper .event p:not(.small) {
  font-size: 1.1em;
  font-weight: 600;
}

.calendar-wrapper .event p.small {
  font-size: 1em;
  display: flex;
  align-items: center;
  gap: 8px;
}

.calendar-wrapper .event.isSmall p:not(.small) {
  font-size: .9em;
}

.calendar-wrapper .event.isSmall p.small {
  font-size: .8em;
}

.calendar-wrapper .event p.small svg {
  width: 12px;
  height: 13px;
  fill: #36b29a;
}

.calendar-wrapper .fc-v-event .fc-event-main {
  border-radius: 4px;
  border-left: 5px solid #36b29a;
  min-height: 2rem;
  color: #000;
  padding: 0;
}

.calendar-wrapper .day-haeder {
  min-height: 3.5rem;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.calendar-wrapper .day-haeder p:first-child {
  padding-top: 0.3rem;
  padding-bottom: 0.2rem;
}

.calendar-wrapper .day-haeder p:last-child {
  border-radius: 50%;
  padding: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.7rem;
  height: 1.7rem;
}

.calendar-wrapper .day-haeder .isToday {
  background-color: #36b29a;
  color: #fff;
  margin: 0 !important;
}

/* .calendar-wrapper .fc-timegrid-event-harness {
  left: 0 !important;
} */

.calendar-wrapper thead .fc-scroller {
  overflow: hidden !important;
}