.bg-gray-for-report .app-main,
.bg-gray-for-report .modern header nav,
.bg-gray-for-report .app-main .app-main__inner {
  background-color: #F8F8F8 !important;
}

.generate-report {
  color: #50AE93;
  margin: 1rem 0;
}

.reportCard-card {
  background-color: #fff;
  border-radius: 10px;
  max-width: 930px;
  padding: 1rem 2rem;
  margin: 2rem auto;
}

.reportCard-title-svg {
  fill: #9E9E9E;
}

.reportCard-card-collapsed-title {
  color: #6C6D6D;
  margin-top: 16px;
  margin-bottom: 8px;
}

.report-template-wrapper {
  color: #4E5BF4;
  gap: 1rem;
}

.report-template-wrapper h6 {
  color: #4E4F4F !important;
}

.report-template-img {
  background-color: #F2F3F7;
  border-radius: 5px;
  overflow: hidden;
  height: 140px;
  width: 168px;
}

.report-template-img img {
  display: block;
  margin: 1.5rem auto 0;
  width: 140px;
}

.reportCard-sections-title {
  color: #6C6D6D;
  margin-top: 2rem;
}

.reportCard-sections-card-wrapper {
  overflow-x: auto;
  gap: 2rem;
  padding: 1rem;
}

.reportCard-sections-card-wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.reportCard-sections-card {
  flex-shrink: 0;
  box-shadow: 0 2px 4px 2px rgb(0 0 0 / 0.1);
  height: 90px;
  width: 210px;
  padding: 1rem;
  border-radius: .5rem;
  transition: transform .2s linear;
}

.reportCard-sections-card:hover {
  transform: scale(1.07);
}

.reportCard-sections-card p {
  color: #1C1C1C;
  font-size: 32px;
}

.reportCard-sections-card span {
  bottom: 5px;
  right: 5px;
  color: #5DD1AD;
}

.reportCard-sections-card svg {
  fill: #5DD1AD;
  width: 16px;
  height: 16px;
  transform: rotateZ(-90deg);
}

.reportCard-card-ind-sec-header h1 {
  font-size: 32px;
}

.reportCard-card-ind-sec-input-wrapper {
  max-width: 350px;
  border-radius: 4px;
}

.reportCard-card-ind-sec-input-wrapper input {
  background-color: inherit;
}

.reportCard-card-ind-sec-input-wrapper svg {
  stroke: rgb(44, 62, 80, .51);
  width: 16px;
  height: 16px;
}

.reportCard-card-ind-sec-header svg {
  stroke: #fff;
  width: 18px;
  height: 18px;
  stroke-width: 2.4;
  margin-right: 8px;
}

.reportCard-card-ind-sec-input-wrapper input::placeholder {
  color: #929293;
}

.reportCard-card-ind-sec-table tr:not(:first-child) {
  border-bottom: 1px solid hsl(195, 15%, 89%, .47);
}

.report-modal-close-icon {
  cursor: pointer;
  height: 40px;
  width: 40px;
  fill: #9E9E9E;
}

.report-modal-wrapper {
  border-radius: 24px;
}

.report-modal-wrapper-h1 {
  font-size: 23px;
}

.report-modal-wrapper select:focus {
  outline: none;
}

.report-preview-modal-center {
  height: 60vh;
  border-radius: 12px;
  overflow-y: scroll;
}

.report-preview-modal-center .template-wrapper {
  margin: 1rem auto;
}

.report-preview-modal-btns-wrapper button {
  transition: transform .2s ease-in;
  width: 150px;
}

.report-preview-modal-btns-wrapper button:hover {
  transform: translatey(-4px);
}

.report-preview-modal-print-btn svg {
  stroke: #909091;
  stroke-width: 2;
}

.report-preview-modal-download-btn svg {
  width: 20px;
  height: 20px;
  stroke: #fff;
  stroke-width: 2.4;
}

.report-edit-modal-layout {
  display: grid;
  grid-template-columns: .6fr 1fr;
  gap: 1rem;
}

.report-edit-modal-card {
  box-shadow: 0 2px 4px 2px rgb(0 0 0 / 0.1);
  border-radius: 12px;
}

.report-edit-modal-card-scholastic-area {
  margin-right: -1.5rem;
  padding-right: 1.5rem;
  max-height: 200px;
  overflow-y: auto;
}

.report-edit-modal-card h3 {
  color: #000 !important;
}

.report-edit-modal-layout input[type="text"] {
  border-width: .5px;
  border-radius: 6px;
  width: 100%;
}

.template-wrapper {
  max-width: 596px;
}

.template-wrapper table {
  border-collapse: separate;
  border-radius: 10px;
}

.template-wrapper-mark-cont {
  border-radius: 10px;
}

.rc-mark-table tr:last-child td:first-child {
  border-radius: 0 0 0 10px;
}

.rc-mark-table tr:last-child td:last-child {
  border-radius: 0 0 10px 0;
}

.template-wrapper-mark-cont h2 {
  border-radius: 10px 10px 0 0;
}

.rc-mark-table tr td,
.rc-score-review-table tr td {
  padding-top: 4px;
  padding-bottom: 4px;
  border-top: 1px solid #9393932e;
}

table tr td:first-child .first-col {
  padding-left: 24px;
  padding-right: 4px;
}

.rc-mark-table tr td:not(:last-child),
.last-td-right-border td:last-child,
.rc-score-review-table td:not(:last-child) {
  border-right: 1px solid #9393932e;
}

.rc-score-review-table tr:first-child td {
  border-top: 0;
}

.report-card-mark-box {
  width: 24px;
  height: 24px;
  border-radius: 3px;
}

.template-wrapper-school-img {
  height: 56px;
  width: 56px;
}

.template-wrapper-result-img {
  width: 20px;
  height: 20px;
}

.back-btn-report-card {
  border-radius: .4rem;
  box-shadow: 1px 1px 1px rgb(0 0 0 / 0.1);
  padding: 2px 12px 2px 8px;
}

.back-btn-report-card:hover {
  box-shadow: 3px 3px 6px rgb(0 0 0 / 0.1);
}

@media (max-width: 900px) {
  .report-edit-modal-layout {
    max-height: 70vh;
    grid-template-columns: 1fr;
    overflow-y: scroll;
  }

  .report-preview-modal-center {
    overflow-y: auto;
    height: auto;
  }
}

@media (min-width: 900px) {
  .report-card-modal-full-wrapper .modal-xl {
    max-width: calc(100% - 3rem);
  }

  .report-edit-modal-left-layout .grid-col-2 {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 576px) {
  .success-generated-modal .modal-dialog {
    max-width: 360px;
  }

  .lock-modal .modal-dialog {
    max-width: fit-content;
  }
}

@media (min-width: 1024px) {
  .report-edit-modal-left-layout .grid-col-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  .report-edit-modal-layout {
    gap: 2rem;
  }
}

@media (min-width: 1200px) {
  .report-card-modal-full-wrapper .modal-xl {
    max-width: 1140px;
  }
}