.flex-1 {
  flex: 1;
}
.first {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 15px;
  padding-top: 20px;
  padding-left: 25px;
  padding-right: 15px;
  padding-bottom: 20px;
  margin-left: 40px !important;
  margin-right: 40px !important;
  border: 1px solid #ddd;
}
.button {
  color: #fff;
  background-color: #06b191;
  border-radius: 15px;
  padding: 3px 12px;
  font-size: 14px;
  outline: none;
}
.third {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 15px;
  padding: 0.8rem 1.2rem;
  margin-top: 25px;
  margin-left: 40px !important;
  margin-right: 40px !important;
  border: 1px solid #ddd;
}
.sd {
  padding-left: -10px;
  padding-right: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0;
  margin-left: 30px !important;
  margin-right: 40px !important;
}
.second {
  border-radius: 15px;
  margin-top: 30px;
  margin-left: 40px !important;
  margin-right: 40px !important;
  border: 1px solid #ddd;
}
.score {
  font-size: 40px;
}
.bigText {
  font-size: 40px;
  margin-top: 0;
}

.heade {
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding: 12px 24px;
}
.dd {
  display: flex;
  flex: 0.3;
  justify-content: space-between;
}
.labels {
  font-size: 12px;
  color: #777;
}
.bodyy {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}
.cc {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cc > img {
  width: 100px;
}
.inner > p {
  font-size: 14px;
  margin-bottom: 0;
}
.inner > h4 {
  margin-top: 0;
  font-size: 30px;
}
