.modern .overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 100000;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 1);
  overflow-x: hidden;
  transition: 0.5s;
}

/* Position the content inside the overlay */

.modern .overlay-content {
  position: relative;
  top: 10%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

/* The navigation links inside the overlay */

.modern .overlay a {
  padding: 8px;
  text-decoration: none;
  color: #818181;
  display: block;
  transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */

.modern .overlay a:hover,
.modern .overlay a:focus {
  color: #f1f1f1;
}

/* Position the close button (top right corner) */

.modern .overlay .closebtn {
  position: absolute;
  top: 20px;
  color: #fff;
  right: 45px;
  font-size: 40px;
}

/* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */

@media screen and (max-height: 450px) {
  .modern .overlay a {
    font-size: 20px;
  }
  .modern .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

/* Loading */

.modern .loader-wrap {
  background: rgba(24, 24, 24, 1);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
}

.modern .loader-wrap .spinner {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  width: 40px;
  margin: auto;
  height: 40px;
}

.modern .loader-wrap .double-bounce1,
.modern .loader-wrap .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.modern .loader-wrap .double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.modern .loader-wrap p {
  top: 50%;
  left: 50%;
  position: absolute;
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-left: -30px;
  font-weight: 600;
  margin-top: 30px;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.modern .header {
  margin-right: 10px;
  margin-left: 90px;
}

.modern .header .navbar {
  /* border-radius: 10px; */
  padding: 0;
}

.modern .header .navbar .site-logo .navbar-brand {
  margin: 0;
  padding: 0;
  display: block;
}

.modern .header .navbar .site-logo .navbar-brand img {
  display: block;
  position: relative;
}

.modern .navbar-right {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  margin-left: auto;
  width: 100%;
  padding: 0 40px;
}

.modern header .navbar .site-logo {
  min-width: 60px;
  /* max-width: 80px; */
  min-height: 50px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
}

/*Sidebar Nav Css Start*/

.modern .aside-left {
  margin: 10px 0 10px 10px;
  width: 70px;
  background: #022636;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 12;
  /* padding: 80px 0 0; */
  transition: all 0.4s ease 0s;
  border-radius: 15px;
  -moz-transition: all 0.4s ease 0s;
  -webkit-transition: all 0.4s ease 0s;
}

.modern .menu {
  padding: 15px 0;
  height: 99vh;
}

.modern .menu > ul {
  margin: 0;
  padding: 0;
}

.modern .menu > ul > li {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.modern .menu > ul > li a i {
  font-size: 24px;
  color: #a0a0a0;
}

.modern .menu > ul > li > a {
  display: block;
  white-space: nowrap;
  position: relative;
  font-size: 14px;
  color: #ffffff;
  line-height: 14px;
  font-weight: 400;
  letter-spacing: 0.025em;
  padding: 15px;
  text-align: center;
}

.modern .menu > ul > li a > span {
  padding: 0 10px;
  min-width: 95px;
  height: 100%;
  line-height: 30px;
  height: 30px;
  visibility: hidden;
  white-space: nowrap;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 100%;
  -moz-transition: all 0.4s ease 0s;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  border-radius: 0 30px 30px 0;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  transform: translate(0, -50%);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  text-align: left;
  background: #522727;
  background: -moz-linear-gradient(left, #522727 0%, #ff3232 100%);
  background: -webkit-linear-gradient(left, #522727 0%, #ff3232 100%);
  background: linear-gradient(to right, #522727 0%, #ff3232 100%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#522727', endColorstr='#ff3232', GradientType=1);
}

.modern .menu > ul > li a > span:before {
  position: absolute;
  content: "";
  left: -15px;
  top: 0;
  width: 15px;
  height: 30px;
  background: #242424;
  background: -moz-linear-gradient(left, #242424 0%, #502727 100%);
  background: -webkit-linear-gradient(left, #242424 0%, #502727 100%);
  background: linear-gradient(to right, #242424 0%, #502727 100%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#242424', endColorstr='#502727', GradientType=1);
  clip-path: polygon(0 50%, 100% 100%, 100% 0);
}

.modern .menu > ul > li:hover a span {
  visibility: visible;
  opacity: 1;
}

.modern .menu > ul > li:hover a i,
.modern .menu > ul > li.active a i {
  color: #fff;
}

.modern .menu-active {
  font-weight: bold;
  background-image: linear-gradient(to right, #ff3059, #022635);
}

.modern .menu span {
  display: inline-block;
  white-space: nowrap;
  transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -webkit-transition: all 0.4s ease 0s;
}

.modern .menu svg {
  display: inline-block;
  font-size: 14px;
  width: 30px !important;
}

.modern .menu > ul > li .fa-angle-down {
  position: absolute;
  right: 10px;
  top: 15px;
  opacity: 0.5;
  transform: rotate(-180deg);
}

.modern .menu > ul > li > a.collapsed .fa-angle-down {
  transform: rotate(0deg);
}

.modern .menu .submenu {
  padding: 0 0 10px 30px;
  margin: 0;
}

.modern .menu .submenu li {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.modern .menu .submenu li a {
  display: block;
  white-space: nowrap;
  position: relative;
  font-size: 13px;
  color: #aab2bd;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 0.025em;
  padding: 8px 30px;
}

.modern .menu .submenu li a:hover {
  color: #fff;
}

.modern .app-main__outer {
  padding-left: 70px !important;
}

.modern .app-main .app-main__inner {
  padding: 0px 18px 0px 35px;
}

.modern .fixed-header .app-main {
  padding-top: 60px !important;
}

.modern .notification-icon {
  color: #084353;
  font-size: 15px;
  transform-origin: center;
  transition: 100ms all ease-in;
}

.modern .notification-icon:hover {
  color: #08435386;
  transform: scale(1.1);
}

.modern .img-fluid {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .modern .aside-left {
    display: none;
  }
  .modern .header {
    margin-left: 10px;
  }
  .modern .show-mdsm {
    display: block !important;
  }
  .modern .overlay {
    display: block !important;
  }
  .modern .app-main__outer {
    padding-left: 0px !important;
  }
}

/* SIDE NAVIGATION ON HOVER */
.attendance_hover {
  padding-left: 10%;
  padding-right: 10%;
  width: 105%;
}

.sidenav-menu {
  background-color: #084353;
  border-color: #084353;
  border-width: 2px;
  border-radius: 7px;
}

.sidenav-item:hover {
  background-image: linear-gradient(to right, #ff3059, #022635) !important;
}

.fa {
  color: white;
}

/* override for default link class in reactstrap */
.dropright .btn-secondary {
  border: none !important;
  background-color: inherit !important;
  border-color: none !important;
}

.dropright .btn-secondary:hover {
  background-color: inherit !important;
}

.dropright .sidenav-menu [button] :hover {
  background-image: none !important;
}

/* override for side nav not turning white on hover */
.dropright .dropdown-menu .dropdown-item {
  background-color: #084353 !important;
}

/*FOUNDER DASHBOARD CSS */
.foundercount > .card {
  border: none;
  border-radius: 10px;
}

.superadmin_count {
  background: linear-gradient(to right, #5fc8ef 0%, #016ce8 100%);
}

.admin_count {
  background: linear-gradient(to right, #66cdaa 0%, #008b8b 100%);
}

.teacher_count {
  background: linear-gradient(to right, #ffa07a 0%, #fb3253 100%);
}

.student_count {
  background: linear-gradient(to right, #8777a9 0%, #4b0082 100%);
}

.school_table_card,
.not_paid_card {
  border: none;
  border-radius: 10px;
}

.school_table_card thead,
.not_paid_card thead {
  background-color: #073e4d;
}

.school_table_card table {
  border-radius: 10px;
  max-height: 100% !important;
  overflow-y: scroll;
}

.table-hover > tbody > tr:hover {
  font-weight: bold;
}

/* .school_table_card {
  height: 83%;
  overflow-y: scroll;
} */

/*SEARCHBOX IN WRAPPER*/
.SearchBox {
  top: 11px;
  right: 10vw !important;
  border-radius: 5px;
  border: 2px solid #bdc7cb;
}

.SearchBox .form-control {
  width: 32vw;
  border: none;
  padding: 15px !important;
  border-radius: 50px;
}

.SearchBox > ul > li :hover {
  background-color: rgb(4, 177, 145) !important;
}

.assignment-badge-rework {
  background-color: "#fdc113";
  color: "#806228"
}