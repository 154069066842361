@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700&family=Poppins:wght@400;500;600;700;800;900&display=swap');

html {
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
}

body {
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
}

button {
  outline: none !important;
  border: none;
  background: transparent;
}

.dis-grayed:disabled {
  background-color: #666;
}

input[type="radio"]:focus-visible,
input[type="checkbox"]:focus-visible {
  outline: 1px solid #01636b;
}

a:hover {
  text-decoration: none;
}

select.modern-arrow {
  appearance: none;
  padding-right: 26px;
  background-position: 97%;
  background-size: 20px;
  background-image: url(../images/chevron-down.png);
  background-repeat: no-repeat;
}

.border-3 {
  border-width: 3px !important;
}

.table td {
  padding: 0.5rem !important;
}

input {
  outline: none;
  border: none;
}

header {
  height: 7.5vh;
}

.input {
  font-size: 20px;
  color: #000000;
  line-height: 1.2;
  display: block;
  height: 10vh;
  font-weight: bold;
  min-width: 30vw;
  background: #afcce2;
  padding: 0 20px 0 23px;
  border-radius: 20px;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.form-control:focus {
  border-color: #00434c;
  box-shadow: 0 0px 0px #00434c;
  outline: 0 none;
}

.disabled-btn:disabled {
  transform: none !important;
  opacity: .8;
}

.bg-lightblue {
  background-color: #dcddf6;
}

.bg-lightblue th {
  padding: 0.5rem;
}

.bg-gray {
  background-color: gray;
}

.bg-lightgray {
  background-color: rgb(206, 204, 204);
}

.app-header {
  background: #0d0f15e6 !important;
}

.app-header__logo {
  background: #0d0f15e6 !important;
}

.app-sidebar {
  background: #022635 !important;
}

.metismenu-icon {
  color: white !important;
  opacity: 0.8 !important;
  font-size: 1.2rem !important;
}

.vertical-nav-menu a {
  color: #ffffff !important;
}

.vertical-nav-menu li a.mm-active {
  font-weight: bold;
  background-image: linear-gradient(to right, rgb(255, 0, 85), #022635);
}

.vertical-nav-menu li a {
  height: auto;
}

.vertical-nav-menu li a:hover {
  transition: none !important;
  background: linear-gradient(to right, rgb(255, 0, 85), #022635) !important;
  background-image: linear-gradient(to right, rgb(255, 0, 85), #022635);
  background: -webkit-linear-gradient(to right,
      rgb(255, 0, 85),
      #022635) !important;
  background: -o-linear-gradient(to right, rgb(255, 0, 85), #022635) !important;
  background: -moz-linear-gradient(to right,
      rgb(255, 0, 85),
      #022635) !important;
  background: linear-gradient(to right, rgb(255, 0, 85), #022635) !important;
}

.three-icons a {
  color: black !important;
}

.dropdown .btn:focus {
  box-shadow: none !important;
}

.box-shadow-none {
  box-shadow: none !important;
}

.loginFormDiv {
  background: #022635;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidemenu-round-logo {
  color: #333333;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #afcce2;
}

.sidemenu-square-logo {
  color: #333333;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  border-radius: 20%;
  background-color: #afcce2;
}

.login-form-logo {
  font-size: 40px;
  color: #333333;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #afcce2;
  margin: 0 auto;
}

.school-form-logo {
  font-size: 40px;
  color: #333333;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #e1e8ee;
  margin: 0 auto;
}

.container-login-form-btn {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.login-form-btn {
  color: #ffffff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  background: #ff3059;
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all ease-out 0.4s;
}

.login-form-btn:hover {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  background: #9152f8;
  transition: all ease-in 0.4s;
  position: relative;
  z-index: 1;
}

.forgotPassword {
  font-size: 13px;
  color: #afcce2;
  line-height: 1.5;
}

.font-plusJak {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.fs-6 {
  font-size: 6px;
}

.fs-7 {
  font-size: 7px;
}

.fs-8 {
  font-size: 8px;
}

.fs-9 {
  font-size: 9px;
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-25 {
  font-size: 25px;
}

.fs-30 {
  font-size: 30px;
}

.fs-35 {
  font-size: 35px;
}

.fw-1000 {
  font-weight: 1000;
}

.fw-900 {
  font-weight: 900;
}

.fw-700 {
  font-weight: 700;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.rotate-Z-180 {
  transform: rotateZ(180deg);
}

.clr-50AE93 {
  color: #50AE93;
}

.clr-BEBFBF {
  color: #BEBFBF;
}

.clr-878787 {
  color: #878787;
}

.clr-464747 {
  color: #464747;
}

.clr-00A69B {
  color: #00A69B;
}

.clr-EF395C {
  color: #EF395C;
}

.clr-020C26 {
  color: #020C26;
}

.clr-ff3059 {
  color: #ff3059;
}

.clr-065B98 {
  color: #065B98;
}

.clr-1E1E1E {
  color: #1E1E1E;
}

.clr-828181 {
  color: #828181;
}

.clr-050202 {
  color: #050202;
}

.clr-848484 {
  color: #848484;
}

.clr-232323 {
  color: #232323;
}

.clr-898989 {
  color: #898989;
}

.clr-4E5BF4 {
  color: #4E5BF4;
}

.clr-909091 {
  color: #909091;
}

.clr-929392 {
  color: #929392;
}

.clr-121212 {
  color: #121212;
}

.clr-5E5F5F {
  color: #5E5F5F;
}

.clr-2B2B2B {
  color: #2B2B2B;
}

.clr-171717 {
  color: #171717;
}

.clr-4F4F4F {
  color: #4F4F4F;
}

.clr-435092 {
  color: #435092 !important;
}

.clr-838384 {
  color: #838384;
}

.clr-9B9A9A {
  color: #9B9A9A;
}

.clr-686868 {
  color: #686868;
}

.clr-606161 {
  color: #606161;
}

.clr-9E9E9E {
  color: #9E9E9E;
}

.clr-7A7A7A {
  color: #7A7A7A;
}

.clr-0D2535 {
  color: #0D2535;
}

.clr-5B5B5B {
  color: #5B5B5B;
}

.clr-868686 {
  color: #868686;
}

.clr-8D8D8D {
  color: #8D8D8D;
}

.clr-0E7D5D {
  color: #0E7D5D;
}

.clr-30876E {
  color: #30876E;
}

.clr-201F1F {
  color: #201F1F;
}

.clr-767676 {
  color: #767676;
}

.clr-A6A7A8 {
  color: #A6A7A8;
}

.clr-929292 {
  color: #929292;
}

.clr-8A8A8A {
  color: #8A8A8A;
}

.clr-A1A1A1 {
  color: #A1A1A1;
}

.clr-4472E9 {
  color: #4472E9;
}

.clr-898888 {
  color: #898888;
}

.clr-DFDEDE {
  color: #DFDEDE;
}

.clr-939191 {
  color: #939191;
}

.clr-606060 {
  color: #606060;
}

.clr-666 {
  color: #666;
}

.clr-1F1F1F {
  color: #1F1F1F;
}

.bg-F2F4F8 {
  background-color: #F2F4F8;
}

.bg-F8F8F8 {
  background-color: #F8F8F8
}

.bg-E8E8E8 {
  background-color: #E8E8E8;
}

.bg-e8e8e829 {
  background-color: #e8e8e829;
}

.bg-011918 {
  background-color: #011918;
}

.bg-5DD1AD {
  background-color: #5DD1AD
}

.bg-66B8A0 {
  background-color: #66B8A0;
}

.bg-50AE93 {
  background-color: #50AE93;
}

.bg-bcbdc030 {
  background-color: #bcbdc030;
}

.bg-DC625E {
  background-color: #DC625E;
}

.bg-FAB94B {
  background-color: #FAB94B;
}

.bg-4FC1B6 {
  background-color: #4FC1B6;
}

.bg-0A9394 {
  background-color: #0A9394;
}

.bg-4DB784 {
  background-color: #4DB784;
}

.bg-099F97 {
  background-color: #099F97;
}

.bg-B6C9E8 {
  background-color: #B6C9E8;
}

.bg-89898926 {
  background-color: #89898926;
}

.bg-F7F7F8 {
  background-color: #F7F7F8;
}

.bg-DCF4A8 {
  background-color: #DCF4A8;
}

.bg-D9D9D9 {
  background-color: #D9D9D9;
}

.bg-EDEDED {
  background-color: #EDEDED;
}

.bg-CFCFD0 {
  background-color: #CFCFD0;
}

.bg-4061D3 {
  background-color: #4061D3;
}

.bg-CECFD0 {
  background-color: #CECFD0;
}

.bg-F0F0F099 {
  background-color: #F0F0F099;
}

.bg-F7F7F7 {
  background-color: #F7F7F7;
}

.bg-FAFAFA {
  background-color: #FAFAFA;
}

.bg-EDEDEDA8 {
  background-color: #EDEDEDA8;
}

.bg-50AE934D {
  background-color: #50AE934D;
}

.bg-F6F7F8 {
  background-color: #F6F7F8;
}

.bg-E5E5E5 {
  background-color: #E5E5E5;
}

.bg-8C8C8C {
  background-color: #8C8C8C;
}

.bg-E1E1E1 {
  background-color: #E1E1E1;
}

.bg-EAEAEA {
  background-color: #EAEAEA;
}

.bg-ffffff9e {
  background: #ffffff9e;
}

.border-C4C4C4 {
  border: 1px solid #C4C4C4;
}

.border-9E9E9E {
  border: 1px solid #9E9E9E;
}

.border-9393932e {
  border: 1px solid #9393932e;
}

.border-t-56565617 {
  border-top: 1px solid #56565617;
}

.border-b-DFDEDED9 {
  border-bottom: 1px solid #DFDEDED9;
}

.border-eee {
  border: 1px solid #eee;
}

.grid-col-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.grid-col-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.gap-8px {
  gap: .5rem;
}

.gap-1rem {
  gap: 1rem;
}

.gap-2rem {
  gap: 2rem;
}

.pl-2rem {
  padding-left: 2rem;
}

.w-18 {
  width: 18px;
}

.h-18 {
  height: 18px;
}

.min-w-150px {
  min-width: 150px;
}

.br-5px {
  border-radius: 5px;
}

.br-8px {
  border-radius: 8px;
}

.br-10 {
  border-radius: 10px;
}

.table-fixed {
  table-layout: fixed;
}

.transition-width {
  transition: width 200ms linear;
}

.greet-round {
  border-radius: 50%;
  height: 60px;
  width: 60px;
  padding: 5px;
  font-size: 35px;
  text-align: center;
}

.table-top-exclam {
  padding: 5px;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0px 0px 3px 6px rgba(191, 219, 220, 1);
}

.table-top-exclam-color {
  color: #ffd36a;
}

/* card in super admin dashboard*/

.height-max-content {
  height: max-content;
}

.cursor-pointer {
  cursor: pointer !important;
}

.css-pc1z43,
.css-h2i4hf {
  border-top: 5px solid #ff3059 !important;
}

.btn-outline-save {
  color: #00304c;
  border-color: #00304c;
}

.btn-outline-save:hover {
  color: #fff;
  background-color: #00304c;
  border-color: #00304c;
}

.btn-outline-quick {
  color: #045b62;
  border-color: #045b62;
}

.btn-outline-quick:hover {
  color: #fff;
  background-color: #045b62;
  border-color: #045b62;
}

.border-bottom {
  border-bottom: 5px solid #00304c !important;
}

.border-bottom-primary {
  border-bottom: 5px solid #045b62 !important;
}

.border-bottom-success {
  border-bottom: 5px solid #32e787 !important;
}

.border-bottom-warning {
  border-bottom: 5px solid #d5e732 !important;
}

.border-bottom-danger {
  border-bottom: 5px solid #ff3059 !important;
}

.dotted {
  border: 0.5px dashed rgba(0, 0, 0, 0.219);
}

.school-record-table table {
  border-bottom: 0px !important;
}

.bg-absent {
  background-color: #ff3059;
}

.bg-today-assignment {
  background-color: #13af8f !important;
}

.bg-present {
  background-color: rgba(71, 153, 151, 0.22);
}

.text-theme {
  color: #00434c !important;
}

.text-theme-red {
  color: #ff3059;
}

.text-theme-light {
  color: #0fb293;
}

.first-child-curved {
  background-color: #084353;
  border-radius: 25px;
  border-top-right-radius: 0%;
  border-bottom-right-radius: 0%;
}

.last-child-curved {
  background-color: #084353;
  border-radius: 25px;
  border-top-left-radius: 0%;
  border-bottom-left-radius: 0%;
}

.first-child-curved-td {
  background-color: rgba(71, 153, 151, 0.22);
  border-radius: 25px;
  border-top-right-radius: 0%;
  border-bottom-right-radius: 0%;
}

.last-child-curved-td {
  background-color: rgba(71, 153, 151, 0.22);
  border-radius: 25px;
  border-top-left-radius: 0%;
  border-bottom-left-radius: 0%;
}

.border-theme-light {
  border: 2px solid #479997;
}

.border-theme {
  border: 2px solid rgba(71, 153, 151);
}

.bg-lightblue-td {
  background-color: rgba(71, 153, 151, 0.22);
}

.toast__custom .icon-error {
  width: 70px;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  border-radius: 5px 0 0 5px;
  background-image: linear-gradient(to right, #ff8799, #ff3059);
  display: flex;
  justify-content: center;
  align-items: center;
}

.toast__custom .message {
  padding-left: 70px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.toast__custom .icon-success {
  width: 70px;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  border-radius: 5px 0 0 5px;
  background-image: linear-gradient(to right, #06b191, #094353);
  display: flex;
  justify-content: center;
  align-items: center;
}

.award-color {
  color: #fbbf12;
}

.attention-color {
  color: #ef4055;
}

.grade-a1 {
  background-color: #3fd6cc;
  /* border-radius: 50%; */
  width: 31px;
  height: 31px;
  padding: 5px;
  color: black;
  font-weight: 1000;
  border-radius: 50%;
  text-align: center;
}

.grade-a2 {
  background-color: #afa93b;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 5px;
  color: black;
  font-weight: 1000;
}

.grade-b1 {
  background-color: #edab5d;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 5px;
  color: black;
  font-weight: 1000;
}

.grade-b2 {
  background-color: #8b73ef;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 5px;
  color: black;
  font-weight: 1000;
}

.grade-c1 {
  background-color: #3093ee;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 5px;
  color: black;
  font-weight: 1000;
}

.grade-c2 {
  background-color: #ba3c6c;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 5px;
  color: black;
  font-weight: 1000;
}

.grade-d {
  background-color: #c69c6c;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 5px;
  color: black;
  font-weight: 1000;
}

.grade-e1 {
  background-color: #ce7668;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 5px;
  color: black;
  font-weight: 1000;
}

.grade-e2 {
  background-color: #ed5959;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 5px;
  color: black;
  font-weight: 1000;
}

.bt-10 {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.bb-10 {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.btn-theme {
  background-color: #00434c;
  color: white;
  text-decoration: none;
}

.btn-theme:hover {
  background-color: #00434c;
  color: white;
  text-decoration: none;
}

.wave-container {
  position: relative;
  color: #fff;
  text-align: center;
  overflow: hidden;
}

.yes-button:hover {
  background-color: #df4759 !important;
}

.bg-theme-light {
  background-color: #084353;
}

.bg-theme-verylight {
  background-color: rgba(71, 153, 151, 0.22);
}

.school-record-table .table th .table td {
  border: 1px !important;
  padding: 0.4rem !important;
  font-size: 13px !important;
}

.school-record-table .fixed-table-container {
  border: 0px !important;
}

.text-green {
  color: #1da589;
}

.text-red {
  color: #ef5a88;
}

.badge-danger {
  color: #fff;
  background-color: #ef5a88;
}

.badge-secondary {
  color: #fff;
  background-color: #1da589;
}

.bg-lightblue-custom {
  background-color: #d1d3ff !important;
}

.attendance-table .table th .table td {
  border: 1px !important;
  padding: 0.4rem !important;
  font-size: 13px !important;
}

.attendance-table .fixed-table-container {
  border: 0px !important;
}

.attendance-card {
  background: -webkit-linear-gradient(to right, #479997, #09537e);
  background: -o-linear-gradient(to right, #479997, #09537e);
  background: -moz-linear-gradient(to right, #479997, #09537e);
  background: linear-gradient(to right, #479997, #09537e);
  color: #ffffff;
}

.attendance-card-absent {
  background: -webkit-linear-gradient(to right, #ff3059, #ff8799);
  background: -o-linear-gradient(to right, #ff3059, #ff8799);
  background: -moz-linear-gradient(to right, #ff3059, #ff8799);
  background: linear-gradient(to right, #ff3059, #ff8799);
  color: #ffffff;
}

.border-right-attendance-report {
  border-right: 1px solid slategray !important;
}

.border-left-color {
  border-left: 3px solid #084353 !important;
}

.input-border-color {
  border-color: #084353 !important;
}

.border-left-color-report {
  border-left: 5px solid #084353 !important;
}

.border-bottom-absent {
  border-bottom: 3px solid #ff3059 !important;
}

.border-bottom-present {
  border-bottom: 3px solid #18a489 !important;
}

.border-bottom-unmarked {
  border-bottom: 3px solid gray !important;
}

.unstyled::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}

.border-top-absent {
  border-top: 3px solid #f75889 !important;
}

.border-top-present {
  border-top: 3px solid #18a489 !important;
}

.border-top-unmarked {
  border-top: 3px solid #f3c04f !important;
}

.bg-absent {
  background-color: #f75889 !important;
}

.bg-present {
  background-color: #18a489 !important;
}

.bg-unmarked {
  background-color: #f3c04f !important;
}

.bg-pass {
  background-color: #027c78 !important;
}

.bg-fail {
  background-color: #ff5050 !important;
}

.attendance-present {
  background: -webkit-linear-gradient(to right, #00304c, #479997);
  background: -o-linear-gradient(to right, #00304c, #479997);
  background: -moz-linear-gradient(to right, #00304c, #479997);
  background: linear-gradient(to right, #00304c, #479997);
  padding: 5px 10px;
  border-radius: 50%;
  cursor: pointer;
  font-weight: 900;
  color: white;
}

.theme-rounded {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 8px;
  background: #084353;
  color: white;
  font-weight: 1000;
}

.attendance-inprogress {
  background: -webkit-linear-gradient(to right, #ffc107, #dacc7e);
  background: -o-linear-gradient(to right, #ffc107, #dacc7e);
  background: -moz-linear-gradient(to right, #ffc107, #dacc7e);
  background: linear-gradient(to right, #ffc107, #dacc7e);
  padding: 5px 10px;
  border-radius: 50%;
  font-weight: 900;
  cursor: pointer;
  color: white;
}

.bg-theme {
  background-color: #00304c;
}

.attendance-absent {
  background: -webkit-linear-gradient(to right, #ff3059, #ff8799);
  background: -o-linear-gradient(to right, #ff3059, #ff8799);
  background: -moz-linear-gradient(to right, #ff3059, #ff8799);
  background: linear-gradient(to right, #ff3059, #ff8799);
  padding: 5px 10px;
  border-radius: 50%;
  font-weight: 900;
  cursor: pointer;
  color: white;
}

.attendance-unmarked {
  background-color: gray;
  padding: 5px 10px;
  border-radius: 50%;
  font-weight: 900;
  cursor: pointer;
  color: white;
}

.btn-redink {
  background-color: rgba(71, 153, 151, 0.22);
  color: white;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.btn-redink:hover {
  background-color: #ff3059;
  color: rgb(0, 0, 0);
}

.outer-box-hierarchy-academic {
  display: flex;
  align-content: center;
  justify-content: center;
  background-color: #022635;
  border-radius: 8px;
  padding: 10px;
  height: 110px;
}

.outer-box-hierarchy-mark-upload {
  align-content: center;
  border-radius: 8px;
  background-color: #01353b;
  justify-content: center;
  padding: 10px;
}

.cent-btn {
  position: fixed;
  bottom: 45%;
  font-size: 20px;
  right: 25px;
  margin: 0;
  padding: 12px 16px;
  background: #01353b;
  border: none;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  color: #fff;
  z-index: 10;
}

.inner-box-hierarchy-academic {
  background-color: #01636b;
  border-radius: 6px;
  display: flex;
  font-size: 10px;
  flex-direction: column;
  width: 120px;
  justify-content: center;
}

.outer-box-hierarchy {
  overflow: auto;
  display: flex;
  align-content: center;
  border-radius: 8px;
  background-color: #022635;
  /* height: 100px; */
  /* width: 120px; */
  /* justify-content: center; */
  padding: 10px;
}

.inner-box-hierarchy {
  background-color: #01636b;
  border-radius: 6px;
  display: flex;
  font-size: 12px;
  flex-direction: column;
  width: 8rem;
}

.selected {
  border: 2px solid white;
}

.disabled {
  pointer-events: none !important;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #2196f3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.class-wise-progress {
  display: flex;
  height: 25px;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.auth-curve {
  z-index: 1;
}

.side-badge {
  padding-top: 1.6vw;
  margin-top: 1.6vw;
  width: 10rem;
  height: 7rem;
}

.due-card-container {
  margin-left: 13vh;
  width: 75vw;
}

@media (max-width: 768px) {

  html,
  body {
    font-size: 12px !important;
  }

  .md-d-none {
    display: none !important;
  }

  .auth-curve {
    display: none !important;
  }

  .classSearchBox,
  .addGradeButton {
    width: 100%;
    margin-top: 15px;
  }

  .teachersSearchBox,
  .addTeacherButton {
    width: 100%;
    margin-top: 15px;
  }

  .messagesTable {
    margin-top: 20px;
    padding-left: 15px;
  }
}

.XsHBg {
  width: 23rem;
  margin-left: 2.5rem;
}

/* react_npm*/
#tiles-1 {
  background-color: #084353;
  border-radius: 5px;
  box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#tiles-2 {
  background-color: #469896;
  border-radius: 5px;
  box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.tile-p {
  top: 2%;
  padding-left: 2%;
  bottom: 10%;
  font-size: 0.8vw;
  width: 70%;
  display: block;
  text-align: left;
  color: #ffffff;
}

.tile-h {
  top: 20%;
  padding-left: 2%;
  text-align: left;
  margin-bottom: 1%;
  padding-top: 2%;
  font-size: 1vw;
  width: 100%;
  display: block;
  color: #ffffff;
  font-size: 12;
}

.tile-t {
  padding-left: 2%;
  margin-bottom: 0%;
  width: 100%;
  display: block;
  font-size: 0.7vw;
  color: #ffffff;
  bottom: 10%;
  text-align: left;
  position: absolute;
}

#img-news {
  width: 100%;
  height: 100%;
  padding: 5%;
}

.event-tile:nth-child(odd) {
  width: 98%;
  margin: 1%;
  padding: 5px;
  font-size: 0.8vw;
  background-color: #084353;
  border-radius: 5px;
  color: #ffffff;
  box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.event-tile:nth-child(even) {
  color: #ffffff;
  width: 98%;
  margin: 1%;
  padding: 5px;
  font-size: 0.8vw;
  background-color: #469896;
  border-radius: 5px;
  box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#day-1-tile {
  margin-top: 0%;
  margin-bottom: 5%;
  float: right;
}

#task-tile-1 {
  width: 100%;
  border-radius: 7px;
  margin-bottom: 5%;
  position: relative;
  height: 10vh;
  background-color: #dcdcdc;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#task-tile-2 {
  width: 100%;
  border-radius: 7px;
  margin-bottom: 5%;
  bottom: 0px;
  height: 10vh;
  background-color: #dcdcdc;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.react-calendar__navigation {
  border-radius: 10px;
}

.react-calendar__navigation__label {
  border-radius: 10px;
  color: #ffffff;
}

.react-calendar {
  max-width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1em !important;
  border: none !important;
  border-radius: 40px;
  background-color: #084353 !important;
}

.react-calendar,
.react-calendar *:before,
.react-calendar *:after {
  box-sizing: border-box;
  border-radius: 10px !important;
  text-shadow: 0 10px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 10%;
}

.react-calendar abbr {
  text-shadow: 0 10px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: #ffffff;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus,
.react-calendar__tile abbr:enabled:hover,
.react-calendar__tile--active abbr {
  background-color: #1da589 !important;
  border-radius: 10px;
  opacity: 68%;
  color: #ffffff !important;
}

.react-calendar__tile--active {
  background-color: #1da589 !important;
  border-radius: 10px;
  opacity: 68%;
  color: #ffffff !important;
}

.react-calendar__month-view__days__day--neighboringMonth abbr {
  color: #a9a9a9 !important;
  text-shadow: 0 10px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.react-calendar__month-view__days__day--weekend abbr {
  text-shadow: 0 10px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.react-calendar__month-view__weekdays__weekday {
  color: #ffffff !important;
  border-radius: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.react-calendar__tile,
.react-calendar__decade-view__years__year {
  color: #ffffff !important;
}

.react-calendar__navigation__arrow,
.react-calendar__navigation__prev-button {
  color: #ffffff !important;
}

.react-calendar__tile--hover {
  background-color: #1da589 !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #1da589 !important;
  color: #084353 !important;
}

.react-calendar__navigation__label:enabled:hover,
.react-calendar__navigation__label:enabled:focus {
  background-color: #1da589 !important;
  color: #084353 !important;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #1da589 !important;
}

.react-calendar__tile--hasActive {
  background: #1da589 !important;
}

abbr {
  text-decoration: none !important;
}

.report-board {
  border-collapse: collapse;
}

.report-board td {
  padding: 0px 0px 10px 0px;
}

.report-board td:first-child {
  border-right: 1px solid #a9a9a9;
  text-align: right;
}

.left-board {
  padding: 5px 10px;
  background-color: #eee;
  border-radius: 25px 0px 0px 25px;
  width: fit-content;
  float: right;
  margin-right: -1px;
}

.right-board {
  padding: 5px 10px;
  background-color: #eee;
  border-radius: 0px 25px 25px 0px;
  width: fit-content;
}

.report-board td:first-child .bg-success {
  border-right: 1px solid #09b08a !important;
}

.report-board td:first-child .bg-danger {
  border-right: 1px solid #cd264e !important;
}

.report-board td:first-child .bg-warning {
  border-right: 1px solid #c3523b !important;
}

.report-board td:first-child .bg-yellow {
  border-right: 1px solid #d4c709 !important;
}

.checked {
  color: #f7931e !important;
  opacity: 100% !important;
}

.fa-star {
  color: #479997;
  opacity: 22%;
}

.scroll-false {
  overflow-y: scroll;
}

.scroll-false::-webkit-scrollbar {
  display: none !important;
}

.profile {
  text-align: center;
  align-items: center;
}

.cardone {
  height: 100%;
}

.personaldetails {
  text-align: left;
}

.btn-color {
  background-color: #a9a9a9;
}

.markwidth {
  width: 50px;
  margin-top: -5px;
}

.heading {
  text-align: left;
}

.skills {
  margin-top: 25px;
}

.statusbox {
  height: 100%;
}

.aligntext {
  font-size: 14px;
}

.alignmargin {
  margin-top: -32px;
}

.status {
  margin-left: 2pc;
}

.assmarkbox {
  width: 10pc;
  height: 2pc;
}

.sliders {
  width: auto;
  text-align: left;
  font-size: 20px;
  font-family: fantasy;
  font-style: italic;
  color: #022636;
  text-align: auto;
}

.perform {
  color: #000000;
  font-size: medium;
}

.myclass {
  margin-top: 25px;
}

.one {
  width: 115px;
  height: 115px;
  background-color: #0fb293;
}

.card1 {
  background-color: #0fb293;
  width: 135px;
}

.card2 {
  background-color: #ff8799;
  width: 135px;
}

.myskills {
  align-self: start;
}

.mydetails {
  color: #09b08a;
  text-align: left;
}

.count-cards2 {
  width: 55px;
  height: 55px;
  text-align: center;
  border-radius: 100%;
  background-color: #0a4354;
  /* font-size: 24px; */
  margin-top: -11px;
}

.count-cards3 {
  width: 55px;
  height: 55px;
  text-align: center;
  border-radius: 100%;
  background-color: #0a4354;
  font-size: 24px;
  margin-top: -11px;
}

.count-cards3:hover {
  background-color: white;
  color: #00304c !important;
}

.border-success {
  border: 2px solid #06b191 !important;
}

.border-warning {
  border: 2px solid #d89e2a !important;
}

.border-danger {
  border: 2px solid #ed365c !important;
}

.content {
  margin-top: 5px;
  margin-left: 50px;
}

.perform {
  text-align: justify;
  -moz-text-align-last: justify;
  text-align-last: justify;
}

.img1 {
  border-radius: 50%;
  width: 50%;
  margin-top: 60px;
}

.badges {
  margin-top: -25px;
}

.dropdownbox {
  justify-content: flex-end;
}

.dropdownmenu {
  width: 250pt;
  height: 250pt;
}

.recentbox {
  height: 23pc;
}

.heightbox {
  height: 100%;
}

.boxheigth {
  height: 100%;
}

.border-color {
  border-color: yellow;
}

.datebox {
  margin-left: 8pc;
}

.extracur {
  margin-top: -25px;
}

.custom-range::-webkit-slider-runnable-track {
  background: #022636;
}

.btnsave {
  background-color: #0db88d;
  margin-bottom: 10px;
}

.btncom {
  background-color: #d8e6db;
  margin-bottom: 10px;
}

.namebox {
  margin-top: 10px;
}

.custom-range::-webkit-slider-thumb {
  background: #1616c4;
}

.fa-check-circle {
  font-size: 25px;
  color: #9eaab0;
}

.fa-check-circle-hover {
  font-size: 25px;
  color: #06b191;
}

.fa-redo {
  color: #9eaab0;
  font-size: 23px;
}

.fa-clock {
  font-size: 25px;
  color: #9eaab0;
}

.fa-clock-hover {
  font-size: 25px;
  color: #ed365c;
}

.fa-redo-hover {
  font-size: 23px;
  color: #d89e2a;
}

.adjustbox {
  text-indent: -45%;
}

.haha {
  width: auto;
}

.mybutton {
  align-self: flex-end;
  top: -23pt;
}

.proimg {
  margin-top: 25px;
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease-in-out;
  opacity: 0;
  position: absolute;
  top: 69%;
  left: 50%;
  transform: translate(-50%, -75%);
  -ms-transform: translate(-50%, -75%);
  /* text-align: center; */
}

.scl-logo {
  position: relative;
}

.scl-logo:hover .proimg {
  opacity: 0.1;
}

.scl-logo:hover .middle {
  opacity: 1;
}

.admin-stu-table-btn {
  background-color: #0db88d;
  border-radius: 3rem;
  margin-left: 4px;
  font-size: 12px;
  padding: 2px .5rem;
  display: none;
  color: #fff;
}

.admin-stu-table-btn svg {
  --color: #fff;
  display: inline-block;
  width: .8rem;
  margin-right: 6px;
  margin-bottom: 1px;
}

.admin-stu-table tr:hover .admin-stu-table-btn {
  display: inline-block;
}

.admin-stu-table-btn.big {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  width: 5rem;
}

.text {
  cursor: pointer;
  /* background-color: #04AA6D;
  color: white;
  font-size: 16px; */
  padding: 3px 25px;
}

.profile1 {
  text-align: center;
  align-items: center;
  background-color: #022635;
}

.button4 {
  border-radius: 12px;
  background-color: #00434c;
  border: 1.5px solid #06b191 !important;
  width: 6pc;
}

.bgcolor {
  background-color: #f0f0f0;
}

.count-cards-one {
  background-color: #06b191;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 100%;
  border-width: 100px;
  font-size: 20px;
}

.numberbox {
  align-self: flex-end;
  margin-top: -2pc;
  margin-right: 10px;
}

.skillbox {
  margin-bottom: 15px;
}

.mybutton1 {
  align-self: flex-end;
  top: -40pt;
}

.iconbox {
  margin-top: 35px;
}

.skillbgcolor {
  background-color: #06b191;
}

.inputcolor {
  background-color: #f0f0f0;
  border: 1px solid #000000 !important;
  margin-top: -10px;
}

.mybutton2 {
  align-self: flex-end;
  top: -43pt;
}

.cardcolor {
  background-color: #022635;
}

.li-bg-color {
  background-image: linear-gradient(to right, #ff3059, #022635);
}

.news-side {
  overflow-x: hidden;
}

/* Timeline */
.timeline:before {
  content: "";
  position: absolute;
  border-left: 3px dashed #469896;
  top: 6em;
  bottom: 3em;
  left: 50%;
}

.event-timeline:nth-child(even) {
  flex-direction: row-reverse;
}

.event-timeline:nth-child(even) .timeline-date {
  margin-right: 30px;
  padding-bottom: 0px;
  float: right;
}

.event-timeline:nth-child(odd) .timeline-date {
  margin-left: 30px;
  text-align: end;
}

.event-cards {
  margin-bottom: 0px;
}

@media screen and (max-width: 800px) {
  .tile-h {
    font-size: 12px;
  }

  .tile-p {
    font-size: 10px;
  }

  .tile-t {
    font-size: 9px;
  }

  .update {
    font-size: 9px;
  }
}

/* News tab */
#current-news-img {
  width: 90%;
  padding-left: 20px;
}

.tl {
  border-top: 6px solid #022635;
  height: inherit;
}

.news-head {
  font-size: 1.2vw;
}

.news-article {
  text-align: justify;
}

.news-side-tile:nth-child(even) {
  background-color: #084353;
  color: white;
}

.news-side-tile:nth-child(odd) {
  background-color: #469896;
  color: white;
}

@media screen and (max-width: 680px) {
  #current-news-img {
    width: inherit;
  }

  .news-list {
    margin-left: 20px;
  }

  .news-article {
    width: inherit;
  }

  .update {
    font-size: 20px;
  }

  .news-container {
    height: auto;
  }

  .news-head {
    font-size: 4.2vw;
  }

  .modal.right.fade .modal-dialog {
    left: 0px;
    right: 0px;
    height: 90%;
    width: 75%;
  }
}

.modal-backdrop {
  display: none;
}

.modal.right .modal-dialog {
  position: fixed;
  top: 1%;
  margin: auto;
  width: 40%;
  height: 98%;
  right: 10px;
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.slide-in-right {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

/* TIMETABLE */
/* MOBILE AND TAB */
@media only screen and (max-width: 1023px) {
  .timetable-timeline {
    position: relative;
    top: -40px;
    width: 90% !important;
    left: 5%;
  }
}

@media only screen and (max-width: 1024px) {
  .hide-1024 {
    display: none !important;
  }

  .due-card-container {
    margin-left: 9vh;
  }

  .XsHBg {
    width: 27rem;
    margin-left: 5rem;
  }
}

/* LAPTOP */
@media only screen and (min-width: 1024px) {
  .timetable-timeline {
    position: relative;
    width: 90% !important;
    top: -20px !important;
    left: 5%;
  }

  .timetable-days {
    width: 210% !important;
  }

  .timetable-day {
    width: 100% !important;
  }

  .XsHBg {
    width: 34rem;
    margin-left: 11rem;
  }

  .profile-subject {
    width: 9rem;
    overflow-wrap: initial;
  }

  .no-data {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

/* BIG LAPTOP */
@media only screen and (min-width: 1440px) {
  .timetable-timeline {
    width: 90% !important;
    position: relative;
    top: -40px !important;
    left: 5%;
  }

  .timetable-days {
    width: 150% !important;
  }

  .timetable-oneday {
    width: 308px !important;
  }

  .XsHBg {
    width: 27rem;
    margin-left: 9rem;
  }

  .profile-subject {
    width: 9rem;
    overflow-wrap: initial;
  }
}

/* COMMON FOR TIMETABLE */
.timetable {
  height: 468px;
}

.timetable-oneday {
  width: 308px;
  display: block;
}

.timetable-timeline {
  border-radius: 15px;
  border: none;
  box-shadow: none;
  background-color: #084353;
}

.timetable-item {
  border-radius: 10px;
  background-color: #1b5e68;
}

.timetable-day {
  border-radius: 10px;
  height: 16vh;
  background-color: #084353;
}

.timetable .badge {
  background-color: #084353;
}

/* Behaviour UI  */
@media only screen and (max-width: 700px) {
  .student-behaviour-card {
    width: 40% !important;
  }

  .no-data {
    position: absolute;
    top: 50%;
    left: 33%;
  }
}

@media only screen and (min-width: 700px) {
  .student-behaviour-card {
    width: 13%;
  }

  .timetable-oneday {
    width: 288px !important;
  }

  .no-data {
    position: absolute;
    top: 50%;
    left: 38%;
  }
}

.student-behaviour-card {
  background-color: rgb(8, 67, 83);
  border: none;
  border-radius: 10px;
  /* height: 110%; */
}

.student-behaviour-card-min-ht {
  min-height: 187px;
}

.student-behaviour-card-min-ht .card-body {
  margin: auto 0;
}

.group-behaviour-min-ht {
  min-height: 240px;
}

.card-plus {
  background-color: #479997;
  font-size: 10px;
  padding: 4px 5px;
  border-radius: 10px;
}

.card-minus {
  background-color: #e85a58 !important;
  font-size: 10px;
  padding: 4px 5px;
  border-radius: 10px;
}

.scrollbar {
  padding: 2px;
  overflow-y: scroll;
}

.positive-circle {
  background-color: #479997;
  font-size: 12px;
  padding: 5px 5px;
  border-radius: 10px;
}

.negative-circle {
  background-color: #e85a58;
  font-size: 12px;
  padding: 5px 5px;
  border-radius: 10px;
}

.scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 7px;
}

.scrollbar::-webkit-scrollbar-track {
  background: none;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #72b3a4;
  border-radius: 3px;
}

.bg-green {
  background-color: #d6e4e1;
}

.check {
  margin-top: 10%;
}

/* Card Border Removal */
.card {
  border: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.edit-modal {
  overflow-x: hidden;
}

/*Admin Message Page */

.primary-outline-btn {
  border-color: #00304c;
  color: #00304c;
}

.primary-btn {
  background-color: #00304c;
}

.btn-hover:hover {
  background-color: #0db88d;
  color: white;
}

.right-br {
  border-radius: 4px 0px 0px 4px;
}

.left-br {
  border-radius: 0px 4px 4px 0px;
}

/* student record*/
.records:hover {
  background-color: #cee0dc !important;
  transition: 0.2s 0s ease-out;
  /* height: 70% !important; */
}

.records:hover .student-record1 {
  display: none;
}

.records:hover .student-record2 {
  display: block;
  opacity: 1;
  transition: 0.2s 0.2s ease-out;
}

.student-record2 {
  opacity: 0;
  display: none;
  text-align: center;
}

/* Student Dashboard */

.pill-btn-active {
  background-color: #14dab2;
}

.pill-btn {
  background-color: #a2e6d8;
}

.pill-left {
  border-radius: 10px 0px 0px 10px;
}

.pill-right {
  border-radius: 0px 10px 10px 0px;
}

.bg-card {
  background-color: #084152;
}

.bg-light-primary {
  background-color: #4b7581;
}

.bg-card-light {
  background-color: #f3f3fd;
}

.session-card {
  width: auto;
  /* overflow: scroll; */
}

.assign-card {
  background-color: #f3f3fd;
  border-radius: 7px;
  border: 0.7px solid rgb(189, 189, 189);
}

.upcoming-card {
  border: 0.7px solid rgb(189, 189, 189);
  border-left: 10px solid #027c78;
  border-radius: 5px;
}

[data-letters]:before {
  content: attr(data-letters);
  display: inline-block;
  font-size: 1.5em;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  text-align: center;
  border-radius: 50%;
  background: #0eb293;
  vertical-align: middle;
  margin-right: 1em;
  color: white;
}

.img-width {
  right: 0;
  top: -105px;
  position: absolute;
  width: 300px;
  height: 300px;
}

.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.scrolling-wrapper .card {
  box-shadow: none;
  flex: 0 0 auto;
}

.date-con {
  width: 20vh !important;
}

.button {
  border: none;
  color: #084353;
}

.dateListScrollable {
  display: flex;
  width: 200%;
  overflow-x: scroll;
  scrollbar-width: none;
  margin: 2px 0 2px -20px;
  -webkit-overflow-scrolling: touch;
}

.dateListScrollable::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 200% !important;
  display: none;
}

.monthContainer {
  display: flex;
  flex-direction: column;
}

.monthYearLabel {
  align-self: flex-start;
  z-index: 3;
  font-size: 15px;
  font-weight: bold;
  position: sticky;
  top: 0;
  left: 0;
  width: max-content;
}

.dateDayItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0 0 0 5px;
  width: 45px;
  height: 49px;
  flex-shrink: 0;
}

.daysContainer {
  display: flex;
  width: inherit;
  z-index: 1;
}

.dayLabel {
  font-size: 12px;
  margin: 4px 0 0 0;
}

.dateLabel {
  font-size: 18px;
}

/* Teacher Assignments */
@media only screen and (max-width: 700px) {
  .side-badge {
    margin-left: -3vh !important;
    margin-top: 2.6vh;
    height: 9vw;
    padding-top: 1.2vw;
  }

  .due-card-container {
    margin-left: 12vw;
    width: 65vw;
  }
}

.count-cards {
  width: 45px;
  height: 45px;
  text-align: center;
  border-radius: 100%;
  border-width: 100px;
  background-color: #0a4354;
  font-size: 24px;
}

.side-badge {
  position: absolute;
  left: -7%;
  text-align: center;
  border-radius: 15px;
  background-color: #0a4354;
  color: white;
}

.due-card {
  background-color: #0a4354;
  color: white;
  border-radius: 10px;
  width: 280px;
  height: 130px;
}

.home-work-pill {
  background-color: #7bc9ad;
  color: #0a4354;
}

.exclaimation-icon {
  margin-left: 5px;
  color: #ff3059;
  background-color: #ff3059;
  font-size: 14px;
  padding: 4px;
  padding-left: 7px;
  width: 22px;
  height: 20px;
  border-radius: 50%;
}

.tick-icon {
  margin-left: 5px;
  color: #18a489;
  background-color: #18a489;
  font-size: 10px;
  padding: 3px;
  border-radius: 50%;
}

/* ASSIGNMENT VIEW */
.student_nav_button {
  background-color: #d7e6e3;
  width: 15rem;
}

.nav_button {
  background-color: #0fae89;
}

.student_navigation {
  margin-right: 35%;
}

.markscard {
  border-radius: 10px;
  height: 100%;
}

.markscard .card-footer {
  background-color: white !important;
}

.submissionstatus {
  background-color: #eeeeee;
}

.mark_obtained {
  width: 5rem;
}

.mark_obtained .form-control {
  height: 4rem;
  border-color: #00434c;
  border-width: 1.5px;
  border-radius: 10px;
}

.remark_given .form-control {
  height: 12rem;
  font-size: large;
  border-color: #00434c;
  border-width: 1.5px;
  border-radius: 20px;
}

.approve_btn {
  border-radius: 10px;
  background-color: rgb(6, 176, 143);
  width: 50%;
}

.approve_btn_border {
  border-radius: 10px;
  border-color: rgb(6, 176, 143);
  border-width: 1;
  width: 50%;
}

.rework_btn {
  border-radius: 10px;
  background-color: rgb(214, 159, 43);
  width: 50%;
}

.rework_btn_border {
  border-radius: 10px;
  border-color: rgb(214, 159, 43);
  border-width: 1;
  width: 50%;
}

.done_btn {
  position: relative;
  background-color: rgb(9, 68, 82);
  color: white;
}

.assignment_view {
  overflow-y: scroll;
  height: 100%;
  background-color: #000000;
  display: flex;
  flex-direction: row-reverse;
}

.line {
  height: 200%;
}

.assignment_row {
  height: 80%;
}

.communication-inner-dash {
  border-radius: 25px 0px 25px 25px;
  background-color: #0a4354;
  color: white;
  padding: 30px;
}

.behaviour-background {
  background-color: rgba(71, 153, 151, 0.8);
  color: white;
}

.communication-mail-list {
  background-color: #cccccc;
  border-radius: 25px 25px 0px 25px;
  padding-left: 0px;
  padding-right: 0px;
}

.selected-mail-tab {
  border-radius: 0px 20px 0px 0px;
  background-color: #cccccc;
}

.select-mail-bg {
  border-radius: 20px 20px 0px 0px;
}

.unselect-mail-bg {
  background-color: #cccccc;
}

.unselected-mail-tab {
  background-color: #0a4354;
  color: white !important;
  border-radius: 0px 20px 0px 0px;
}

.communication-panel:hover {
  background-color: #185663;
  border-radius: 20px;
}

.communication-active {
  background-color: #479997 !important;
  border-radius: 20px !important;
}

.report-label {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #e87b59;
  margin-top: 4.5px;
  margin-left: 0.5vw;
}

.report-label-2 {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #e87b59;
  margin-top: 8px;
  padding-block: 3px;
  margin-right: 4px;
}

.info-label {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #dfbe63;
  margin-top: 4.5px;
  margin-left: 0.5vw;
}

.highp-label {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #5863a2;
  margin-top: 4.5px;
  margin-left: 0.5vw;
}

.is-clipped {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mail-card {
  border-radius: 15px;
}

.mail-card-active {
  border: 3px solid #06b191 !important;
}

.mail-card:hover {
  box-shadow: 0 0 20px rgba(3, 3, 3, 0.521);
}

.mail-card:focus {
  width: 200%;
}

.attachments {
  width: 50vh;
  height: 8vh;
  margin-top: 7px;
  text-align: center;
  border-radius: 15px;
  background-color: rgba(13, 177, 145, 0.41);
}

@media only screen and (max-width: 690px) {
  .right-col {
    margin-top: 8vw !important;
  }
}

@media only screen and (max-width: 575px) {
  .side-badge {
    width: 6rem;
    height: 7rem;
  }

  .right-col {
    margin-top: 25vh !important;
  }
}

.recent_test_score_card {
  background-image: url(../images/recent_test_score.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-blend-mode: multiply;
}

h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: #ffffff !important;
  font-weight: 500;
}

.no-shadow {
  box-shadow: none;
}

.empty {
  background-color: #185665;
}

.text-dark-green {
  color: #084353;
}

/* report top tab styles */
.top-tab-pills {
  background-color: #d8d8d8;
  padding: 4px 14px;
  border-radius: 8px;
}

.top-tab-pills>button {
  font-size: 1em;
  border-radius: 6px;
  color: #094251;
  font-weight: bold;
  width: 100px;
}

.top-tab-pills>button.active {
  color: #fff;
  background-color: #094251;
}

.custom-image-input {
  width: 80px;
  height: 80px;
  border-radius: 80px;
  border: 1px solid var(--primary);
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.custom-image-input>label {
  color: var(--primary);
}

.custom-image-input>input {
  display: none;
}

.css-1aseh1t {
  color: #000000 !important;
}

.ant-picker-input>input[disabled] {
  color: rgba(0, 0, 0, 0.65) !important;
}

.bg-brand {
  background-color: #094251 !important;
}

.text-brand {
  color: #094251 !important;
}

.border-brand {
  border-color: #094251 !important;
}

.text-green {
  color: #04b191 !important;
}

.bg-green {
  background-color: #04b191 !important;
}

.border-green {
  border-color: #04b191 !important;
}

.onboarding-stage {
  opacity: 0;
  pointer-events: none;
  z-index: 150;
  position: absolute;
  top: -12px;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 150ms all ease-in;
  display: flex;
}

.onboarding-stage-active {
  opacity: 1;
  pointer-events: all;
  top: 0;
}

.onboarding-stage-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 4em 2.5em;
  height: calc(100% - 8em);
  width: calc(100% - 5em);
  flex: 1;
  overflow: hidden;
}

.custom-scrollbars::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.custom-scrollbars::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #fff;
}

.custom-scrollbars::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.custom-scrollbars::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.2);
}

.teacher-test-preview .teacher-test-preview-buttons {
  opacity: 0;
  transition: 150ms opacity ease-in;
}

.teacher-test-preview:hover .teacher-test-preview-buttons {
  opacity: 1;
}

.modal-backdrop-show {
  display: block !important;
}

.w-50vw {
  width: 50vw !important;
  max-width: 50vw !important;
}

.border-28px {
  border-radius: 28px !important;
}

.border-14px {
  border-radius: 14px !important;
}

.modal-dialog-shadow-none>.modal-dialog {
  box-shadow: none !important;
}

.question-add-wrapper>div {
  transform: scale(0);
  opacity: 0.3;
  transition: 150ms all ease-in;
}

.question-add-wrapper:hover>div {
  transform: scale(1);
  opacity: 1;
}

.react-pdf__Document {
  border-radius: 8px;
  max-width: 100%;
  position: relative;
  user-select: none;
  cursor: default;
}

.react-pdf__Page {
  width: 100%;
}

.react-pdf__Page canvas {
  width: 100% !important;
  height: auto !important;
}

.react-pdf__Page__svg {
  width: auto !important;
  height: auto !important;
  border-radius: 8px;
}

.question-card {
  background-color: #fff;
  border: 1px solid #eaeaea;
  position: relative;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(209, 209, 209, 1);
  -moz-box-shadow: 0px 0px 0px 0px rgba(209, 209, 209, 1);
  box-shadow: 0px 0px 0px 0px rgba(209, 209, 209, 1);
  transition: 150ms all ease-in;
}

.question-card[data-dragging="true"] {
  -webkit-box-shadow: 2px 2px 7px -1px rgba(209, 209, 209, 1);
  -moz-box-shadow: 2px 2px 7px -1px rgba(209, 209, 209, 1);
  box-shadow: 2px 2px 7px -1px rgba(209, 209, 209, 1);
}

.hover-parent .hover-child {
  opacity: 0;
  transition: 150ms opacity ease-in;
}

.hover-parent:hover .hover-child {
  opacity: 1;
}

.matching-ans {
  border-radius: 8px;
  vertical-align: center;
  background-color: #fafafa;
}

.add-question-btn {
  border-radius: 8px !important;
  border: none !important;
  background-color: rgba(45, 96, 110, 0.45) !important;
  color: #05262b !important;
  box-shadow: none !important;
}

.add-question-btn:focus {
  border: none !important;
  box-shadow: 0 0 0 0.1rem #094353 !important;
}

.question-bank-que {
  transition: 150ms background-color ease-in;
}

.question-bank-que:hover {
  background-color: #e6eced;
}

.loader {
  border: 2px solid #f3f3f3;
  border-radius: 60%;
  border-top: 2px solid #3498db;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  margin-right: 20px;
  margin-top: 0px;
}

.working-day {
  background-color: #04b191 !important;
  border-radius: 10px !important;
  margin: 2px 0px !important;
}

.holiday {
  background-color: #ff3059 !important;
  border-radius: 10px !important;
  margin: 2px 0px !important;
}

.noo-data {
  background-color: #094353 !important;
  border-radius: 10px !important;
  margin: 2px 0px !important;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.custom-calendar {
  border-radius: 10px !important;
  border-width: 1px solid white;
  background-color: #084353 !important;
  border-width: 2px;
}

.Calendar {
  padding-top: 0 !important;
  height: none !important;
  width: 100% !important;
  box-shadow: none !important;
  /* background-color: #084353 !important; */
  min-height: auto !important;
}

.custom-today-day {
  /* color: #fff !important; */
  border: 1px solid #fff !important;
  /* border-radius: 10px !important; */
  border-width: 2px;
}

.custom-today-day::after {
  visibility: hidden;
  /* hide small border under the text */
}

.Calendar__sectionWrapper,
.Calendar__weekDays,
.Calendar__header {
  background-color: #084353;
}

.Calendar__section {
  margin-top: 1em !important;
  padding: 3.3em;
  padding-top: 0em !important;
}

.Calendar__weekDays {
  margin-bottom: 0 !important;
  color: #fff !important;
  padding: 0 2.7em !important;
}

.Calendar__header {
  color: #fff !important;
  border-top-left-radius: 5 !important;
}

.custom-days {
  color: #fff !important;
}

.Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-radius: 0% !important;
}

.Calendar__day.-selected,
.Calendar__day.-selectedEnd,
.Calendar__day.-selectedStart {
  background: #bcbcbc !important;
}

.Calendar__day {
  color: #fff !important;
  border-radius: 10px !important;
}

.Calendar__yearSelectorWrapper::before {
  background-image: none !important;
}

.Calendar__footer {
  background-color: #084353 !important;
}

.Calendar__monthArrowWrapper {
  color: #fff !important;
}

.Calendar__monthYear.-shown>*:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

/* .Calendar__monthSelector,
.Calendar__yearSelector {
  background-color: #084353 !important;
} */
.Calendar__yearSelectorWrapper::after {
  background-image: none;
}

.Calendar__monthArrow {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg class='nc-icon-wrapper' fill='%23ffffff'%3E%3Cdefs stroke='none'%3E%3C/defs%3E%3Cpath class='cls-1' d='M12 23.25V.75' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5px'%3E%3C/path%3E%3Cpath class='cls-2' d='M22.5 11.25L12 .75 1.5 11.25' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5px' fill-rule='evenodd'%3E%3C/path%3E%3C/g%3E%3C/svg%3E") !important;
}

.Calendar__monthSelectorItem.-active .Calendar__monthSelectorItemText,
.Calendar__yearSelectorItem.-active .Calendar__yearSelectorText {
  background-color: #084353 !important;
}

.purpleDay:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  border: 2px solid rgba(156, 136, 255, 0.7) !important;
}

.orangeDay:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  border: 2px solid rgba(219, 145, 60, 0.7) !important;
}

.yellowDay:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  border: 2px solid rgba(228, 231, 36, 0.7) !important;
}

.navyBlueDay:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  border: 2px solid rgba(52, 73, 94, 0.7) !important;
}

.working-day:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-color: #04b191 !important;
  border-radius: 10px !important;
  margin: 2px 0px !important;
}

.holiday:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  background-color: #ff3059 !important;
  border-radius: 10px !important;
  margin: 2px 0px !important;
}

/*Time line*/

ul.timeline {
  list-style-type: none;
  position: relative;
}

ul.timeline:before {
  content: "";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}

ul.timeline>li {
  margin: 20px 0;
  padding-left: 20px;
}

ul.timeline>li:before {
  content: "";
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid rgb(45, 235, 195);
  background-color: #15af8f;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}

.timeline:before {
  border-left: none !important;
  top: 0em !important;
}

.activeBar {
  background-color: #029176;
  /* height: 50px;
  width: 20px; */
  border-radius: 10px;
  color: #fff;
  margin: -15px 0px;
  margin-right: 15px;
}

.hoveredSubject1 {
  border: 1px solid rgba(239, 55, 92, 0.32);
}

.hoveredSubject2 {
  border: 1px solid rgba(9, 177, 144, 0.29);
}

.hoveredSubject3 {
  border: 1px solid rgba(244, 125, 28, 0.3);
}

.hoveredSubject1:hover {
  background-color: rgba(239, 55, 92, 0.32);
  border: none;
}

.hoveredSubject2:hover {
  background-color: rgba(9, 177, 144, 0.29);
  border: none;
}

.hoveredSubject3:hover {
  background-color: rgba(244, 125, 28, 0.3);
  border: none;
}

.subDropdown {
  background-color: rgb(176, 176, 176) !important;
}

.dropdownItem:hover,
.dropdownItem:focus {
  background-color: #e8edee8c !important;
}

/* .studentProfileBg {
  background-color: #eef1f6 !important;
} */

.firstLetterUpper:first-letter {
  text-transform: uppercase;
}

.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
}

.custom {
  border-radius: 12px;
}

.fixed-top {
  z-index: 508;
}

.custom_fc_frame {
  bottom: 70px !important;
}

.required:after {
  content: " *";
  color: red;
}

.Calendar__section.-hiddenNext,
.Calendar__section.-hiddenPrevious {
  opacity: 0 !important;
}

h6.MuiTypography-h6 {
  color: #333333 !important;
}

/* teacher module scheduler UI CSS start */
.create-schedule-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem 0;
}

/* teacher module scheduler UI CSS end */

/* assignment ui */
.h-radio,
.a-radio,
.p-radio {
  display: none;
}

.h-label,
.a-label,
.p-label {
  color: #094353;
  /* font-size: 20px;  */
  border: 2px solid #06b191;
  align-items: center;
  border-radius: 15px;
  padding: 5px 15px;
  display: flex;
  position: relative;
  width: -webkit-fill-available;
  font-weight: 800;
}

.h-label::before,
.a-label::before,
.p-label::before {
  /* position: absolute; */
  color: #06b191;
  content: "";
  height: 12px;
  width: 12px;
  border: 1px solid;
  border-radius: 50%;
  margin-right: 10px;
}

.h-radio:checked+label,
.a-radio:checked+label,
.p-radio:checked+label {
  background-color: #06b191;
  color: white;
}

.h-radio:checked+label::before,
.a-radio:checked+label::before,
.p-radio:checked+label::before {
  height: 20px;
  width: 20px;
  border: 6px solid white;
  background-color: #06b191;
}

.b-input {
  border: 1px solid gray;
  border-radius: 5px;
  width: inherit;
  padding-left: 10px;
}

.b-body {
  height: 31rem;
}

.add-icon {
  border: 2px dashed #06b191;
  border-radius: 8px;
  width: 7rem;
  height: 6rem;
}

.add-icon-hv {
  transition: color .2s ease-in-out;
  cursor: pointer;
  color: #06b191;
  gap: 4px;
}

.add-icon-hv:hover {
  color: #fff;
  background-color: #06b191;
}

.img-icon {
  border: 8px solid lightgray;
  border-radius: 8px;
  width: 7rem;
  height: 7rem;
}

.plus {
  position: absolute;
  left: 36px;
  font-size: 6rem;
  top: -22px;
  color: #06b191;
}

.img-close {
  position: absolute;
  left: 6.3rem;
  bottom: 5.8rem;
  border: 2px solid red;
  background-color: red;
  text-align: center;
  color: white;
  cursor: pointer;
  width: 25px;
  height: 25px;
  font-weight: bold;
}

/* tab css */
.tab-container {
  display: flex;
  flex-direction: column;
  /* position: relative; */
  /* width: 500px;
  height: 300px; */
  /* background: #f1f1f1; */
  /* margin: 100px auto 0; */
  word-break: break-all;
  /* border: 1px solid rgba(0, 0, 0, 0.274); */
}

.bloc-tabs {
  display: flex;
  border-bottom: 1px solid lightgray;
}

/* not active tab */
.tabs {
  padding: 15px;
  text-align: center;
  width: 50%;
  /* background: rgba(128, 128, 128, 0.075); */
  cursor: pointer;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.274); */
  box-sizing: content-box;
  position: relative;
  outline: none;
}

/* right border between tabs */
/* .tabs:not(:last-child){
  border-right: 1px solid rgba(0, 0, 0, 0.274);
} */

/* active tabs */
.active-tabs {
  background: white;
  /* border-bottom: 1px solid transparent; */
  border-bottom: 5px solid #06b191
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  /* left: 50%; */
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
  /* background: rgb(88, 147, 241); */
}

tb-1 {
  border: none;
  width: 10rem;
}

tb-2 {
  border: none;
  width: 10rem;
}

tb-3 {
  border: none;
}

.content-tabs {
  flex-grow: 1;
}

.content {
  background: white;
  padding: 20px;
  /* width: 100%;
  height: 100%; */
  display: none;
}

.content h2 {
  padding: 0px 0 5px 0px;
}

/* .content hr {
  width: 100px;
  height: 2px;
  background: #222;
  margin-bottom: 5px;
} */
.content p {
  width: 100%;
  height: 100%;
}

.active-content {
  display: block;
}

.all-behaviour {
  /* background-color:lightblue; */
  border: none;
  border-radius: 10px;
  height: 15rem;
  width: 12rem;
  box-shadow: 14px 10px 5px lightgrey;
}

.all-behaviour-positve {
  /* background-color:white; */
  border: none;
  border-radius: 10px;
  height: 15rem;
  width: 12rem;
  box-shadow: 14px 10px 5px lightgrey;
}

.recog-name {
  font-weight: bold;
  font-size: 15px;
  word-break: break-word;
}

.point-card {
  color: #00434c !important;
  font-size: 10px;
  padding: 4px 0px;
  border-radius: 10px;
}

.table .pro-td {
  width: 50%;
  padding-left: 0 !important;
  padding-right: 0px !important;
}

.table .pro-name {
  padding-left: 0 !important;
  padding-right: 10px !important;
}

.subject-checkbox {
  appearance: none;
  -webkit-appearance: none;
  height: 14px;
  width: 14px;
  border: 1px solid;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mini-check-box {
  height: 11px;
  width: 11px;
}

.subject-checkbox:hover {
  box-shadow: 0 0 11px rgb(6, 177, 145, 0.5);
}

.subject-checkbox::after {
  font-family: "Font Awesome 5 Pro";
  content: "\f00c";
  color: white;
  font-weight: bolder;
  font-size: 10px;
  display: none;
}

.mini-check-box::after {
  font-size: 7px;
}

.subject-checkbox:checked {
  background-color: #06b191;
  border: 1px solid #06b191;
}

.subject-checkbox:checked::after {
  display: block;
}

table .checkbox-td {
  width: 22rem;
}

.electives {
  padding: 3px 5px;
  background-color: rgb(108, 117, 125, 0.2);
  margin-right: 10px;
  border-radius: 8px;
  font-size: 13px;
}

.Tdrop {
  background: none;
  border: none;
}

.Tdrop:hover {
  background: none;
  border: none;
}

div.maxmark input[type=text] {
  padding: 10px;
  /* font-size: 17px; */
  border: 1px solid grey;
  float: left;
  width: 7rem;
  height: 2.5rem;
  border-right: none;
  /* background: #f1f1f1; */
  border: none;
}

div.maxmark button {
  float: left;
  width: 2rem;
  height: 2.5rem;
  padding: 5px;
  /* background: #2196F3; */
  background: white;
  /* color: white; */
  font-size: 17px;
  /* border: 1px solid grey; */
  border: none;
  border-left: none;
  cursor: pointer;
}

/* div.maxmark button:hover {
  background: #0b7dda;
} */

div.maxmark::after {
  content: "";
  clear: both;
  display: table;
}

.maxmark {
  width: fit-content;
  border: 1px solid lightgrey;
  padding: 2px;
  background-color: white;
  border-radius: 6px;
}

.noMark {
  width: 7rem;
  height: 2.5rem;
  border-top: none;
  border: 1px solid white;
  box-shadow: 2px 2px 6px 2px grey;
  margin-left: 5px;
  text-align: center;
  font-weight: bolder;
  display: none;
  background: white;
}

.gr-outer-box {
  background: #fff;
  border: 1px solid #D8D8D8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.gr-inner-box {
  background: #EFEFEF;
  border: 1px solid #000;
  box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  color: #404040;
}

.gr-selected {
  background-color: #01636b;
  box-shadow: none;
  border: 2px solid #51AC91;
  color: #fff;
}

.gr-line {
  position: absolute;
  margin-top: 130px;
  margin-left: 55px;
  width: 3px;
  height: 22px;
  background-color: #51AC91;
}

.gr-inner-box:not(.gr-selected) .fa-ellipsis-v {
  color: #404040;
}

.select-box-in-edit-report div::-webkit-scrollbar,
.custom-scrollbar::-webkit-scrollbar {
  height: var(--scroll-w, 5px);
  width: var(--scroll-w, 5px);
}

.select-box-in-edit-report div::-webkit-scrollbar-track,
.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: none;
}

.select-box-in-edit-report div::-webkit-scrollbar-thumb,
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #DBDBDE;
  border-radius: 10px;
  box-shadow: none;
}

.select-box-in-edit-report {
  --scroll-w: 7px
}

.gradebook-setting-wrapper {
  border-radius: 12px;
  background: #0D2534;
  max-width: 350px;
  width: 100%;
}

.gradebook-setting-wrapper-header {
  background-color: #29616A;
  border-radius: 12px 12px 0 0;
  color: "#EAEEEF";
}

.gradebook-setting-subject-name {
  margin: 8px -1rem 0;
  background: rgb(41, 72, 79, .15);
}

.gradebook-setting-wrapper .custom-control-input:checked~.custom-control-label::before {
  background-color: #06B191;
  border-color: #06B191;
}

.gr-book-setting-abs-wrapper {
  right: 20px;
  z-index: 999;
}

.gradebook-subject-setting-box {
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 1rem;
  margin-right: -1rem;
}

.text-20C9A9 {
  color: #20C9A9;
}

.bg-06B191 {
  background-color: #06B191;
}

.bg-B9B9B9 {
  background-color: #B9B9B9;
}

.gap-18px {
  gap: 18px;
}

.gr-show-less-btn svg {
  width: 14px;
  height: 14px;
}

.gr-inner-mark-wrapper {
  padding: 0 30px;
  height: calc(100vh - 60px);
}

.mark-entry-table thead th {
  font-size: 10px;
  font-weight: 500;
}

@media (max-width: 450px) {
  .gr-book-setting-abs-wrapper {
    right: 0;
  }

  .gr-inner-mark-wrapper {
    padding: 0 10px 0 0;
  }
}

.mark-entry-table tr th:nth-child(-n+2),
.mark-entry-table tr td:nth-child(-n+2) {
  background-color: #fff;
  position: sticky;
  z-index: 1;
  left: 0;
}

.mark-entry-table tr th:nth-child(2),
.mark-entry-table tr td:nth-child(2) {
  left: 44px;
}

.mark-commentbox-popover-icon {
  top: 0;
  left: 0;
  bottom: 0;
  padding: 4px;
  padding-right: 8px;
  background-color: rgba(217, 217, 217, .3);
  border-radius: 4px 0 0 4px;
}

.mark-commentbox-popover-icon img {
  width: 24px;
}

.mark-commentbox-popover-tab {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.mark-commentbox-popover-wrapper .popover {
  max-width: 320px;
}

.mark-commentbox-popover-tab button {
  border-bottom: 3px solid transparent;
  padding-right: 0;
  padding-left: 0;
  font-size: 14px;
}

.mark-commentbox-popover-txt-box button {
  line-height: 1.1;
  font-size: 13px;
}

.mark-commentbox-popover-txt-box button:hover {
  background-color: rgba(0, 0, 0, .07);
}

.mark-commentbox-popover-tab button.active {
  border-bottom: 3px solid #50AE93;
}

.mark-commentbox-popover-icon~input {
  min-width: 250px;
}

.mark-commentbox-popover-icon.active~input {
  border-color: #029176 !important;
}

.mark-commentbox-popover-txt-box {
  margin-right: -8px;
  padding-top: 10px;
  padding-right: 4px;
  overflow-y: scroll;
  height: 350px;
}

.mark-commentbox-popover-wrapper span.arrow {
  display: none;
}

.attendance-admin-report-modal .modal-content {
  border-radius: 20px;
  min-height: 90vh;
}

@media (max-width: 900px) {
  .mark-commentbox-popover-tab button {
    font-size: 12px;
  }

  .mark-commentbox-popover-txt-box button {
    font-size: 11px;
  }
}

@media (min-width: 600px) {
  .mark-entry-table thead th {
    font-size: 12px;
    font-weight: 600;
  }
}

@media (min-width: 1200px) {
  .mark-entry-table thead th {
    font-size: 13px;
  }
}