.cla-header .react-calendar {
  background-color: #fff !important;
}

.cla-header .react-calendar {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.cla-header .react-calendar__navigation__label,
.cla-header .react-calendar abbr,
.cla-header .react-calendar__tile,
.cla-header .react-calendar__decade-view__years__year,
.cla-header .react-calendar__navigation__arrow,
.cla-header .react-calendar__navigation__prev-button {
  color: #000 !important;
}

.cla-header .react-calendar__tile:enabled:hover,
.cla-header .react-calendar__tile:enabled:focus,
.cla-header .react-calendar__tile abbr:enabled:hover,
.cla-header .react-calendar__tile--active abbr,
.cla-header .react-calendar__tile--active {
  opacity: 1;
  color: #fff !important;
}

.cla-header .react-calendar__navigation button:enabled:hover,
.cla-header .react-calendar__navigation button:enabled:focus,
.cla-header .react-calendar__navigation__label:enabled:hover,
.cla-header .react-calendar__navigation__label:enabled:focus {
  color: #fff !important;
}

.cla-header .react-calendar__month-view__days__day--neighboringMonth abbr {
  color: #a9a9a9 !important;
}

.cla-header .react-calendar__navigation {
  margin-bottom: 5px;
}